import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from "@angular/core";
import { CMSDatePipe } from './cms-date.pipe';
import { UniqueDataPipe } from './unique-data.pipe';

@NgModule({
    declarations:[CMSDatePipe, UniqueDataPipe],
    imports:[CommonModule],
    exports:[CMSDatePipe,UniqueDataPipe],
    providers:[DatePipe]
})
export class CMSDatePipeModule{
    
}